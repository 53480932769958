<template>
  <div id="derAfw" class="section der-afw">
    <div class="text-wrapper">
      <h3>Wir stehen für die unabhängige Finanzberatung in Deutschland.</h3>
      <div class="content">
        <p>Wir sind unabhängig. Wir sind qualifiziert. Wir sind #DIE34ER. Und das ist unsere Plattform. Hier schließen wir uns zusammen – und engagieren uns gemeinsam für die Zukunft und das gute Standing unseres Berufsstandes.</p>
        <p>#DIE34ER Community ist eine Initiative des Bundesverband Finanzdienstleistung AfW. Alle, die unabhängig beraten, können sich bei der Community kostenfrei registrieren und erhalten dadurch Zugang zu Community-News und Community-Events sowie zu einer wachsenden Zahl attraktiver Vorteile.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DerAfw',
  
}
</script>

<style scoped lang="stylus">

  // .content {
  //   width 47%;
  // }

  h3
    // font-size 1.125rem
    // line-height 1.3125rem
    color #053160
    padding 2rem
    padding-bottom 1.19rem
    // width 50%

  p 
    font-size 0.875rem
    line-height 1.25rem
    padding 0 2rem
    margin-bottom 1.25rem

  // img
  //   width 100vw

  @media (min-width:801px) {
    .der-afw {
      .text-wrapper {
        max-width 1220px
        display flex
        flex-direction row
        margin 0 auto
        padding-block 6.25rem


        // h3,div {
        //   width 50%
        // }

        h3 {
          padding 0
          // font-size 2rem
          // line-height 2.5rem
        }
        p {
          font-size 1.125rem
          line-height 1.625rem
          padding 0
        }

      }
    }
  }

  @media (min-width: 640px) {

}

@media (min-width: 768px) {
  .text-wrapper {
    .content {
      width: 100%;
    }
    h3 {
      width: 100%;
    }
  }
  .der-afw {
    h3, 
    .text-wrapper {
      padding-inline: 3rem
    }
  }
}

@media (min-width: 1024px) {
  .text-wrapper {
    .content {
      width: 47%;
    }
    h3 {
      width: 50%;
    }
  }
}

@media (min-width: 1280px) {
  .der-afw {
    h2, 
    .text-wrapper {
      padding-inline: 0
    }
  }
}


</style>
