<template>
  <div id="highlights" class="section highlights">
    <div class="wrapper">
      <div class="text-wrapper">
        <h2 v-if="!state.isLoggedIn">Deine <span>#DIE34ER</span> HIGHLIGHTS</h2>
        <h2 v-else><span>Hallo {{state.user.data.first_name}},</span><br>Deine #DIE34ER HIGHLIGHTS</h2>
        <p>Gewinne tolle Goodies, triff dich auf spannenden Online-Conferences mit Gleichgesinnten und verpasse keine Insider-News mehr aus deiner Community.</p>
        <br>
        <p v-if="!state.isLoggedIn">Um auf die Highlights zuzugreifen, kannst du dich hier kostenfrei registrieren.</p>
      </div>
      <div v-if="!state.isLoggedIn" class="btn-text-wrapper">
        <div class="btn-wrapper">
          <!-- <a class="btn green" href="#" @click.prevent="state.showRegisterForm = true">Kostenfrei registrieren</a> -->
          <!-- <a class="btn green" href="#" @click.prevent="$modal.show('register-modal')">Kostenfrei registrieren</a> -->
          <router-link class="btn green" :to="'modal'" @click.native="state.modalCurrentStep='register'; $store.trackEvent('CTA', 'Beitragsfrei registrieren')">Registrieren</router-link>
        </div>
        <p>Du bist schon registriert? Hier kannst du dich <a href="#" @click.prevent="$router.push({ name: 'Modal' }); state.modalCurrentStep='login'">einloggen</a>.</p>
      </div>
      <div class="cards-container" id="cardsContainer">
        <div class="card-wrapper" v-for="(card, index) in state.content.highlights.cards" :key="index">
          <div class="card" :id="card.registrationPoint">
            <div class="card-image">
              <img :src="getImgUrl(card.img)" />
              <h3 v-html="card.title"></h3>
            </div>
            <div v-if="card.content.length > 1" class="card-content">
              <!-- <h3>{{card.title}}</h3> -->
              <template v-if="card.content.length > 1">
                <p v-for="(parag, i) in card.content" :key="i" v-html="parag"></p>
              </template>
            </div>
            <div v-else class="card-content" v-html="card.content[0]">
            </div>
            <div class="btn-wrapper" v-if="!compUserData[card.apiData]">
              <a v-if="!card.btnHidden"
                href="#" 
                class="btn green" 
                @click.prevent="handleEventClicks(card);"
              >{{card.btn}}</a>
              <!-- checkbox -->
              <div class="checkbox-wrapper" v-if="card.consent != null" :class="!card.isChecked && card.buttonClicked ? 'has-error':''">
                <input type="checkbox" :id="'checkbox-'+card.registrationPoint" :name="'checkbox-'+card.registrationPoint" v-model="card.isChecked"/>
                <label :for="'checkbox-'+card.registrationPoint">
                  <span class="checkbox-icon"></span>
                  <a href="https://www.bundesverband-finanzdienstleistung.de/nutzungsbedingungen/" target="_blank" class="consent-link" v-if="card.consent != null">{{card.consent}}</a>
                </label>
              </div>
            </div>
            <div v-else class="info-text" v-html="card.noBtn"></div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Highlights',
  data() {
    return {
      state: this.$store.state
    }
  },
  mounted() {
    // console.warn(this.$modal.show('register-modal'));
  },
  computed: {
    compUserData() {
      return this.state.user.data;
    },
  },
  methods: {
    getImgUrl(imageName) {
      let mobileSuffix = ''
      if ( this.isMobile ) {
        mobileSuffix = ''//'_mobile'
      }
      let images = require.context('../assets/img/', false, /\.jpg$/)
      return images('./' + imageName + mobileSuffix + ".jpg")
    },
    handleEventClicks(card) {
      let registrationPoint = card.registrationPoint
      let apiData = card.apiData

      if (!card.isChecked) {
        card.buttonClicked = true;
      }else {
        if (this.state.isLoggedIn) {
          this.updateUser(apiData)
        } else {
          this.state.newMemberCredentials.registration_point = registrationPoint
          localStorage.setItem('bonus', registrationPoint)
          this.state.modalCurrentStep='register-login'
          this.$router.push({ name: 'Modal' })
        }
        card.buttonClicked = true;
        this.$store.trackEvent('CTA', card.trackingAction)
      }
    },
    async updateUser(_data) {
      let toUpdate = {};
      toUpdate[_data] = true;
      await this.$http.patch(this.state.apiUrl + '/users/' +this.state.user.data.id, toUpdate, {
        headers: {
          Authorization: 'Bearer ' + this.state.user.tokens.access_token
        }
      }).then(response => {
        console.log(response.data);
        this.state.user.data = response.data.data;
        this[_data] = true;
      }).catch(error => {
        console.log(error);
      });
    },
    
  },
}
</script>

<style scoped lang="stylus">
.highlights
  // padding-top 1rem
  font-size 1rem
  background-color: #2E2E38
  // padding 8rem 8.5rem 11.5rem
  padding clamp(unquote('2.75rem, 1.5061rem + 5.3074vw, 7.875rem')) clamp(unquote('2.25rem, 0.7027rem + 6.6019vw, 8.625rem')) clamp(unquote('3.75rem, 1.8386rem + 8.1553vw, 11.625rem'))

  .wrapper
    padding-bottom 2.25rem

  .text-wrapper
    width: 100%
    margin 0 auto
    text-align: center
    margin-bottom 3rem;

    p
      color #fff
      // width: 75%
      margin 0 auto

    h2
      // font-size 1.625rem
      // line-height 1.625rem
      color #fff
      text-transform uppercase
      padding-bottom: 2.625rem
      
      // @media (min-width:801px) {
      //   font-size 4.125rem
      //   line-height 4.125rem
      // }

      span
        color #86BD27

  .cards-container {
    margin-top: .5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    .card-wrapper {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      margin-top: -2.5rem;
      width: 100%;

      &:first-child {
        margin-top: 3rem;
      }

        .card {
          width: 100%;
          height: 93.9%;
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          // padding: 1.5rem;
          // border-radius: 0.5rem;
          background-color: #fff;
          margin-bottom: 5.6rem

          .info-text {
            text-align: center;
            margin-bottom: 3rem;
            max-width: 90%;
            margin-inline: auto;
            background-color: #2E2E38
            border: 1px solid #707070
            border-radius: 10px
            padding: 1.8rem
            
            >>> p {
              font-size: 18px;
              line-height: 26px
              color #fff;
            }
          }

          .card-image {
            // width: 100%;
            // height: 100%;
            display: flex;
            justify-content: left;
            align-items: top;
            position relative

            h3 {
              position absolute
              // margin-top: 3.2rem
              // margin-left: 4.625rem
              margin-left: clamp(unquote('0.8125rem, 0.2427rem + 2.4309vw, 2.1875rem'))
              margin-top: clamp(unquote('1.25rem, 0.6802rem + 2.4309vw, 2.625rem'))
              color #fff
              // font-size 2.19rem
              // line-height: 2.19rem
              font-size clamp(unquote('1.125rem, 0.6847rem + 1.8785vw, 2.1875rem'))
              line-height clamp(unquote('1.25rem, 0.8615rem + 1.6575vw, 2.1875rem'))
              text-transform: uppercase

              >>> span {
                color: #86BD27
                font-size clamp(unquote('1.125rem, 0.6847rem + 1.8785vw, 2.1875rem'))
                line-height clamp(unquote('1.25rem, 0.8615rem + 1.6575vw, 2.1875rem'))
              }
            }

            img {
              width: 100%;
              height: 100%;
              // object-fit: cover;
            }
          }

          .card-content {
            width: 100%;
            // height: 30%;
            height: inherit;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            // padding: 2.75rem 4.3rem 3.5rem;
            padding: 22px 19px 30px;

            h3 {
              // font-size: 1.5rem;
              // line-height: 1.5rem;
              color: #053160;
              text-transform: uppercase;
              padding-bottom: 1.5rem;
            }

            ::v-deep p {
              // font-size: 1.125rem;
              // line-height: 1.125rem;
              color: #2E2E38;
              padding-bottom: 1.5rem;

              &:first-of-type {
                font-weight: 600;
              }

              >>> span {
                color: #86BD27;
                font-weight: 600
              }
            }
          }
          .btn-wrapper {
            // width: 90%
            // margin 0 auto
            padding-bottom: 3rem
          }
          .checkbox-wrapper {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            &.has-error {
              label {
                color: #d90000;
                a {
                  color: #d90000;
                }
              }
            }

            input[type="checkbox"] {
              width: 16px
              height: 16px
            }

            .consent-link {
              color #1C1C22
              font-size: .9rem;
              line-height: .9rem;
              text-decoration: underline;
              cursor: pointer;
              padding-left: .5rem;
            }
          }
          // .btn-wrapper {
          //   text-align center
          //   margin-block 0.6rem

          //   .btn {
          //     font-size 0.8125rem
          //     font-weight 900
          //     line-height 1.25rem
          //     padding 0.625rem 2rem
          //     border-radius 18px
          //     color #fff
          //     text-decoration none
          //     text-transform: uppercase
              
          //     &.green {
          //       background-color #86BD27
          //     }
          //   }
          // }
        }
      }
    // }
  }

  // .btn-wrapper {
  //   width: 90%;
  //   margin 5rem auto 0;
    
  //   .btn.btn {
  //     border-radius: 30px
  //     font-size 1.125rem
  //     line-height 1.25rem
  //     padding 1.125rem 3.625rem
  //     width: auto
  //     display: block
  //     text-transform: uppercase
  //   }

  //   .btn:disabled,
  //   .btn[disabled] {
  //     opacity 0.5
  //     pointer-events none
  //   }
  // }

.btn-wrapper {
  // margin-top: 3rem;
  margin-top: 0;
}

.btn-text-wrapper {
  // width: 19rem;
  // .btn-wrapper {
  //   margin-left: auto;
  // }
  text-align: center
  p {
    margin-top: 10px
    font-size 12px;
    line-height 1.25rem
    width: auto
    display: block
    color: #fff
    // width: max-content

    a {
      display: inline-block
      color #fff
      font-size 12px;
      text-decoration underline
    }
  }
}

@media (min-width: 640px) {
  .highlights {
    .cards-container {
      flex-direction: column;
    }
  }
}

@media (min-width: 768px) {
  .highlights {
    .cards-container {
      flex-direction: column;
      .card-wrapper {
        width: 100%;
        // margin-top: 2.5rem;
        // .card {
        //   width: 100%;
        //   height: auto;
        //   .card-image {
        //     width: 100%;
        //     height: auto;
        //   }
        //   .card-content {
        //     width: 100%;
        //     height: auto;
        //   }
        // }
      }
    }
  }
  
}

@media (min-width: 1024px) {
  .highlights {
    .cards-container {
      .card-wrapper {
        &:first-child {
          margin-top: 0;
        }
      }
    }

    .wrapper {
      .card {
        .btn-wrapper {
          width: 65%
        }
      }
      .btn-wrapper {
        width: 33%
      }
      .text-wrapper {
        p {
          width: auto;
        }
      }
    }

    padding-inline: 50px;
    .text-wrapper {
      width: 75vw
      
      p {
        width: 75vw
      }
    }
    .cards-container {
      margin-top: 3.5rem;
      flex-direction: row;
      .card-wrapper {
        width: calc(50% - 1rem);
        margin-top: 0;

        .card {
          .card-content {
            // padding: 2.75rem 4.3rem 3.5rem;
            padding: 2.75rem 2.3rem 0;
          }
          // .btn-wrapper {
          //   width: 50vw;
          //   margin 0 auto
          // }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .highlights {
    .wrapper {
      max-width: 1225px;
      margin: 0 auto;
    }
    .text-wrapper {
      width: 60vw

      p {
        width: 75vw
      }
    }
    .cards-container {
      .card-wrapper {
        width: calc(50% - 2.3rem);

        .card {
          // .btn-wrapper {
          //   width: 25vw;
          //   margin 0 auto
          // }
        }
      }
    }
  }
}
  
</style>
