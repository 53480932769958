<template>
  <div id="bundesverband" class="section more">
    <div class="ribbon-wrapper">
      <img src ="@/assets/img/flag34d.png" class="ribbon" />
    </div>
    <!-- <h2 class="big">Willkommen</h2>
    <h2 class="big green">Im Club</h2> -->
    <div class="text-wrapper">
      <h2 class="big"><span>#DIE34ER</span> sind <br>EINE INITIATIVE DES BUNDES&shy;VERBAND FINANZDIENST&shy;LEISTUNG AFW</h2>
      <p class="content">Die unabhängigen Finanzberaterinnen und Finanzberater in Deutschland brauchen eine starke Stimme, die in Berlin und Brüssel gehört wird. Aber bis heute sind noch zu wenige von ihnen in einem Verband organisiert, der ausschließlich ihre Interessen vertritt.</p>
      <p class="content">Mit Unterstützung von jungen Finanzberaterinnen und Finanzberatern hat der Bundesverband AfW deshalb jetzt die Community #DIE34ER gegründet. Hier engagieren sich alle jungen, fortschrittlichen Beraterinnen und Berater, die gemäß §§ 34 c, d, f, h, i GewO vermitteln, in einer großen Community, um gemeinsam das Image und die Zukunft ihres Berufsstandes zu fördern. Ihr Ziel: die Sicherung und Stärkung der unabhängigen, provisionsbasierten Beratung in Deutschland. Ihr Partner: der Bundesverband Finanzdienstleistung AfW. Deutschlands großer Verband, der  ausschließlich die Interessen unabhängiger Finanzberaterinnen und Finanzberater vertritt, die gemäß §§ 34 c, d, f, h, i GewO registriert sind.</p>
      <!-- <p class="content">Der Verband wird getragen von seinen Mitgliedern. Die Arbeit des Bundesverbandes wird unterstützt von Fördermitgliedern, Medien, Pools und Dienstleistern für unabhängige Finanzberatung. </p> -->
    </div>
    <div class="btn-wrapper">
      <a class="btn green" href="https://mitmachen.bundesverband-finanzdienstleistung.de/" target="_blank" @click="$store.trackEvent('Link', 'Mehr erfahren')">Mehr Erfahren</a>
    </div>
    <!-- <p class="disclaimer">* Für nur 156 Euro im Jahr hilfst du mit, deine Zukunft zu sichern und profitierst darüber hinaus von zahlreichen Vorteilen deiner unabhängigen Community.</p> -->
  </div>
</template>

<script>
export default {
  name: 'More',
  data() {
    return {
      state: this.$store.state,
    }
  },
}
</script>

<style scoped lang="stylus">
  .more
    background-color #053160
    padding 3.25rem 2.25rem 2.25rem
    text-align center
    position relative

  .text-wrapper {
    text-align: left;
  }

  .ribbon-wrapper
    position absolute
    top 0
    // right 16px
    right 4.2vw
    margin-top -10px

    .ribbon 
      width 60px
      filter drop-shadow(0px 5px 5px #000)

    &:before
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 10px 5px;
      border-color: transparent transparent #000 transparent;
      margin-bottom: -10px;
      margin-left: -5px;
  
  .content 
    color #fff
    font-size 0.875rem
    line-height 1.25rem
    // padding 1.25rem 0 2.25rem
    padding-bottom: 1.25rem
  
  .disclaimer
    color #fff
    font-size 0.625rem
    line-height 0.69rem
    font-style italic
    padding-top 4rem

  .big
    // font-size 1.5625rem
    // line-height 1.5625rem
    // font-size: clamp(unquote('1.25rem, 0.2917rem + 4.7917vw, 4.125rem'));
    // line-height: clamp(unquote('1.25rem, 0.2917rem + 4.7917vw, 4.125rem'));
    font-weight 900
    color #fff
    text-transform uppercase
    padding 0
    margin-bottom: 3rem

    span
      color #86BD27

.btn-wrapper
  text-align center
  margin-block 0.6rem

  .btn
    font-size 0.8125rem
    font-weight 900
    line-height 1.25rem
    padding 0.625rem 2rem
    border-radius 18px
    color #fff
    text-decoration none
    text-transform: uppercase
    
    &.green
      background-color #86BD27

// @media (min-width:801px) {

  

// }

@media (min-width: 640px) {
  .more {
    padding 2.25rem
  }
}

@media (min-width: 768px) {

}

@media (min-width: 1024px) {
  .more {

    .text-wrapper {
      max-width: 824px;
      margin: 0 auto;
      width: 80%
      text-align: left;
    }

    // .big {
    //   font-size 3.5rem
    //   line-height 3.5rem
    // }

    .btn-wrapper {
      min-width 280px;
      max-width: 412px;
      width: 35%;
      margin 3rem auto 0;
    }
  }
}

@media (min-width: 1280px) {
  .more {
    padding 7rem 8.5rem 11.5rem

    .text-wrapper{
      width: 85%;
      // margin 0 auto;
      // text-align: left;
    }

    // .big {
    //   font-size 4.125rem
    //   line-height 4.125rem
    // }

    .content {
      // padding-top 2rem
      max-width: 1100px
      margin 0 auto
      font-size 1.125rem
      line-height 1.5rem
    }

    .btn-wrapper {
      width: 25%;
      margin 5rem auto 0;
      
      .btn.btn {
        border-radius: 30px
        font-size 1.125rem
        line-height 1.25rem
        padding 1.125rem 3.625rem
        width: auto
        display: block
        text-transform: uppercase
      }
    }

    .disclaimer {
      font-size 0.875rem
      line-height: 1.25rem
      max-width: 650px
      margin 0 auto
    }

    .ribbon-wrapper {
      width 133px
      margin-top: -24px

      &::before {
        border-width: 0 0 24px 15px;
        margin-bottom: -24px;
        margin-left: -15px;
      }

      .ribbon  {
        width: 133px
      }
    }

  }
}

</style>
