<template>
  <div class="section hero">
    <transition name="fade" mode="out-in">
      <img :src="getImgUrl(getRandomHero().img)" :key="getRandomHero().img" class="background" />
    </transition>
    <img src ="@/assets/img/flag34d.png" class="ribbon" />
    <transition name="fade" mode="out-in">
      <span class="bordered-name" :class="getRandomHero().namePosition" v-html="getRandomHero().name" :key="getRandomHero().name"></span>
    </transition>
    <div class="bottom">
      <h1 class="hero-wrapper">
        <p class="big">Wir sind</p>
        <transition name="scale-rotate" mode="out-in">
          <p class="big green" :key="getRandomHero().title">{{getRandomHero().title}}</p>
        </transition>
        <p class="subtitle">Deutschlands Community unabhängiger<br> Finanzberater:innen</p>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
  },
  data() {
    return {
      isMobile: this.$store.isMobile(),
      heros: [
        {
          name: '–<br/>Patrick<br/>Hamacher<br/>–',
          img: 'hero1',
          title: '#DIE34ER',
          namePosition: 'right'
        },
        {
          name: '–<br/>Franziska<br/>Zepf<br/>–',
          img: 'hero2',
          // title: 'Up-to-date',
          title: '#DIE34ER',
          namePosition: 'right'//'left'
        },
        {
          name: '–<br/>Bastian<br/>Kunkel<br/>–',
          img: 'hero3',
          // title: 'Connected',
          title: '#DIE34ER',
          namePosition: 'right'
        },
      ],
      currentHero: 0,
    }
  },
  mounted() {
    setInterval(() => {
      if (this.currentHero+1 < this.heros.length) {
        this.currentHero = this.currentHero+1
      }else {
        this.currentHero = 0
      }
    }, 5000);
  },
  computed: {
    compRandomHero() {
      return this.heros[Math.floor(Math.random()*this.heros.length)]
    }
  },
  methods: {
    getImgUrl(imageName) {
      let mobileSuffix = ''
      if ( this.isMobile ) {
        mobileSuffix = ''//'_mobile'
      }
      let images = require.context('../assets/img/', false, /\.jpg$/)
      return images('./' + imageName + mobileSuffix + ".jpg")
    },
    getRandomHero() {
      // return this.heros[Math.floor(Math.random()*this.heros.length)]
      return this.heros[this.currentHero]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .hero 
    background-color #000
    // background-image url('~@/assets/img/hero1.jpg')
    height: 100vh;
    width: 100%;
    background-size: 165vmax;
    background-position: 47% 53px;
    background-repeat: no-repeat;
    position relative

    .desktop-only {
      display: none;
    }

  .background
    height: 77vh
    width 100%
    object-fit cover
    // object-position 47% 53px
    object-position 75% 53px

  .ribbon 
    width 81px
    margin-top 39px
    position absolute
    right 16px
    top: 1.3rem

  .big
    font-size 3.81rem
    font-weight 900
    color #fff
    text-align center
    text-transform uppercase
    line-height 2.75rem
    margin 0

    &.green
      font-size 2.56rem
      color #86BD27

  .subtitle
    // font-size 0.875rem
    font-size: clamp(unquote('0.875rem, 0.6875rem + 0.9375vw, 1.4375rem'));
    line-height: clamp(unquote('1.25rem, 1.0417rem + 1.0417vw, 1.875rem'));
    color #fff
    text-align center
    // line-height 1.25rem
    margin 0
    margin-top 1.25rem
    font-weight normal

  .bottom 
    position absolute
    bottom 2.69rem
    width 100%

  .bordered-name
    font-style italic
    font-size 0.625rem
    line-height 0.69rem
    color #fff
    position absolute
    top 48%
    text-align center

    &.left
      left 1rem

    &.right
      right 1rem


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-move {
  transition transform 0.8s ease;
}

.scale-enter-active, .scale-leave-active {
  transition: all 0.5s linear;
}

.scale-enter-from, .scale-leave-to {
  opacity: 0;
  transform: scale(1.5);
}

.scale-rotate-enter-active, .scale-rotate-leave-active {
  transition: all 0.5s ease;
}

.scale-rotate-enter-from, .scale-rotate-leave-to {
  opacity: 0;
  transform: scale(2.2) rotate(3turn);
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}


.scale-slide-enter-from {
  left: -100%;
}


.scale-slide-enter-to {
  left: 0%;
}


.scale-slide-leave-from {
  transform: scale(1);
}


.scale-slide-leave-to {
  transform: scale(0.8);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}


.slide-enter-to {
  position: absolute;
  right: 0;
}


.slide-enter-from {
  position: absolute;
  right: -100%;
}


.slide-leave-to {
  position: absolute;
  left: -100%;
}


.slide-leave-from {
  position: absolute;
  left: 0;
}

@media (min-width:801px) {
  .hero {
    height: 795px;
    overflow hidden

    .desktop-only {
      display: block
    }

    .background {
      // height 795px;
      height 1000px;
      // transform: translate(0px, -180px)
      transform: translate(0px, -9.4vw)
    }

    .ribbon {
      width 135px
      // right 5.5rem
      right 4.6vw
      top: 2rem;
      transform: translateZ(0) scale(1, 1);
    }

    .bordered-name {
      font-size 1.125rem
      line-height: 1.25rem
      &.right {
        // right 16rem
        right 13vw
      }
    }

    .bottom {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .hero-wrapper {
        // padding-left: 12.5rem
        padding-left: 10.5vw
        width: fit-content
        height: 260px

        .big {
          // font-size 10.125rem
          font-size: 8.45vw
          // line-height: 8.25rem
          line-height: 6.88vw
          text-align: left
          &.green {
            // font-size 10.25rem
            font-size: 8.55vw
          }
        }
        .subtitle {
          // font-size: 1.5rem
          line-height: 1.875rem
          font-size: 1.25vw
          line-height: 1.56vw
          // margin-top: 2.625rem
          margin-top: 2.2vw
        }
      }

    }
  }
}

</style>
