<template>
  <div id="ambassadors" class="section ambassadors">
    <div class="wrapper">
      <h2 class="big green">Bei uns bist du in allerbester <span class="highlight blue">Gesellschaft</span></h2>
      <div class="subtitle-wrapper">
        <h3 class="green">Lern diejenigen kennen, die unsere Botschaft stolz nach außen tragen.</h3>
        <p>Zu unseren Mitgliedern gehören zahlreiche erfolgreiche Menschen, die innovative Unternehmen führen. Sie repräsentieren unsere Botschaft und stärken aktiv das Image der unabhängigen Beratung – sowohl in der Branche als auch in der breiten Öffentlichkeit.</p>
      </div>
    </div>

    <div class="mobile-only" v-if="isMobile" style="width:100%;margin:0 auto 30px auto;height:350px" :style=" {display: isMobile ? 'block' : 'none' } ">
          <!-- Using the slider component -->
          <slider ref="slider" :options="options">
              <!-- slideritem wrapped package with the components you need -->
              <slideritem v-for="(item,index) in someList" :key="index" :style="item.style">
                <div class="img-wrapper">
                  <img :src="getImgUrl(item.img)" alt="" />
                  <div class="name-wrapper">
                    <p class="first name">{{item.fName}}</p>
                    <p class="last name">{{item.lName}}</p>
                  </div>
                </div>
                <div class="content">
                  <p v-html="item.html"></p>
                </div>
              </slideritem>
              <!-- Customizable loading -->
              <div slot="loading">loading...</div>
          </slider>
    </div>
    
    <div class="desktop-only" v-if="!isMobile">
      <div class="card-container">
        <div class="card" v-for="(item,index) in someList" :key="index">
          <div class="img-wrapper">
            <img :src="getImgUrl(item.img)" alt="">
            <div class="name-wrapper">
              <p class="first name">{{item.fName}}</p>
              <p class="last name">{{item.lName}}</p>
            </div>
          </div>
          <div class="content">
            <p v-html="item.html"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper">
      <div class="video-wrapper">
        <div style="padding-bottom: 2rem;">
          <h3 style="width: max-content;" class="green">Patrick Hamacher findet:</h3>
          <h3 class="blue">&bdquo;Du bist unabhängig? Dann gehörst Du zu uns!&ldquo;</h3>
        </div>
        <!-- <img src="@/assets/img/basti.jpg"> -->
        <silent-box :gallery="gallery"></silent-box>
      </div>
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'

export default {
  name: 'Ambassadors',
  components: {
    slider,
    slideritem
  },
  data () {
    return {
      isMobile: this.$store.isMobile(),
      //data list [array]
      someList:[
        {
          img: 'fzapf2',//'https://via.placeholder.com/300x130.png',
          fName: 'Franziska',
          lName: 'Zepf',
          html: 'Mit ihrem authentischen Auftritt in den sozialen Medien prägt Franziska das Bild einer modernen unabhängigen Beratung: digital, ortsungebunden – und höchstpersönlich. Triff Franziska bei den #DIE34ER!',
          style: {
            'position': 'relative',
            'width': '80.8%',
            'height': '267px',
            'margin-right': '5%'
          }
        },
        {
          img: 'phamacher2',//'https://via.placeholder.com/300x130.png',
          fName: 'Patrick',
          lName: 'Hamacher',
          html: 'Mit seinem Blog „WAS-IST-VERSICHERUNG“ und der Podcast-Reihe „Versicherungsflüsterer“ zeigt Patrick eindrucksvoll, dass unabhängige Beratung endgültig im Hier und Jetzt angekommen ist. Du auch? Patrick ist Teil der Community #DIE34ER!',
          style: {
            'position': 'relative',
            'width': '80.8%',
            'height': '267px',
            'margin-right': '5%'
          }
        },
        {
          img: 'bkunkel3',//'https://via.placeholder.com/300x130.png',
          fName: 'Bastian',
          lName: 'Kunkel',
          html: 'Auf YouTube, Instagram und TikTok verzeichnet Bastian mit „Versicherungen mit Kopf“ hunderttausende Follower. So begeistert man also junge Zielgruppen! Als ein Teil der Community #DIE34ER engagiert er sich für die Zukunft und das Image der unabhängigen Beratung.',
          style: {
            'position': 'relative',
            'width': '80.8%',
            'height': '267px',
            'margin-right': '5%'
          }
        }
      ],
      // gallery: [
      //   {
      //     src: 'https://www.youtube.com/watch?v=gSYCHvyKIsw',
      //     thumbnail: '../assets/img/basti.jpg',
      //     autoplay: true,
      //     controls: true,
      //   }
      // ],
      //Slider configuration [obj]
      options: {
        currentPage: 0,
        // itemAnimation: true,
        centeredSlides: true,
        // loopedSlides: 1,
        slidesToScroll: 1,
      }
    }
  },
  mounted () {
    // setTimeout(() => {
    //   document.getElementById('silentbox-gallery').getElementsByTagName('img')[0].setAttribute('loading', 'eager')
    // }, 10)
  },
  computed: {
    gallery: function() {      
      let gallery = [
        {
          // src: 'https://www.youtube.com/watch?v=gSYCHvyKIsw',
          // src: 'https://hamacherversicherung-my.sharepoint.com/personal/patrick_hamacher-versicherungsmakler_de/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fpatrick%5Fhamacher%2Dversicherungsmakler%5Fde%2FDocuments%2F%5FDie34er%5F%2FVideo%2DPatrick%2Emp4&parent=%2Fpersonal%2Fpatrick%5Fhamacher%2Dversicherungsmakler%5Fde%2FDocuments%2F%5FDie34er%5F&ga=1',
          // src: 'https://birkenbeul-digital.de/production/afw34/static/videos/patrick.mp4',
          src: 'https://player.vimeo.com/video/694046479?h=337b5e0d84',
          thumbnail: this.getImgUrl('yt_ph'),
          autoplay: true,
          controls: true,
          lazyLoading: false,
        }
      ];

      return gallery;
    }
  },
  methods: {
    getImgUrl(imageName) {
      let images = require.context('../assets/img/', false, /\.jpg$/)
      return images('./' + imageName + ".jpg")
    },
  },
}
</script>

<style scoped lang="stylus">
  .desktop-only
    display none

  .ambassadors
    padding-block 2.25rem

    .wrapper
      padding-inline 2.25rem

      .subtitle-wrapper
        margin-top: 2rem;

    img
      border-radius 0.5rem
      width 100%
      margin-top 1.3125rem

    h2
      // font-size 1.125rem
      // line-height 1.3125rem
      font-weight 900
      color #86BD27
      margin-top 1.3125rem
      padding-right 3.8125rem

      &.blue
        color #053160
        margin-top 0

    .big 
      // font-size 1.5625rem
      // line-height 1.5625rem
      font-weight 900
      color #053160
      text-transform uppercase
      padding 0
      padding-right: 5rem

      .highlight
        color #86BD27
    
    p
      font-size 0.875rem
      line-height 1.25rem
      color #000
      margin-top 0.75rem

  // .slider-item {
  //   transform:scale(.8);
  //   transition-timing-function: ease;
  //   transition-duration: 300ms;
  // }
  // .slider-item.slider-active {
  //   transform:scale(1);
  //   z-index: 999;
  // }
  // .slider-item.slider-active-copy {
  //   transform:scale(1);
  //   z-index: 999;
  // }

  ::v-deep .swiper-container-horizontal 
    .slider-pagination-bullet-active
      background #86BD27 none repeat scroll 0 0
  
  ::v-deep .swiper-container-horizontal 
    > * 
      > .slider-pagination-bullet
        border-radius 0
        width 9px
        height 9px
        margin 0 5px

  .slider-item
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius 8px
    display: flex;
    flex-direction: column;
    
    .img-wrapper
      // position absolute
      height: 130px;
      top: 0;
      width: 100%;
      text-align left
      overflow: hidden
      
      img 
        margin 0
      
      .name-wrapper
        position absolute
        top 0
        left 0
        padding-left 0.625rem
        height 130px
        display flex
        flex-direction column
        justify-content center
        
        p
          margin 0
          font-size 1.5625rem
          line-height 1.5625rem
          font-weight 900
          &.first
            color #053160
          &.last
            color #86BD27


    .content
      // position absolute
      text-align left
      width 100%
      height 140px
      white-space normal
      padding: 1.125rem;

      p
        font-size 0.8125rem
        line-height 1.125rem
        color #000
        margin 0

  
@media (min-width:801px) {
  .mobile-only {
    display none
  }

  .wrapper {
    max-width: 1220px
    padding 0!important
    margin 0 auto

    h2.big {
      width: 50%
      // font-size: 4.125rem
      // line-height: 4.125rem
      padding 0
      // margin-bottom: 1.5rem
      margin-bottom: 4.5rem
    }

    .subtitle-wrapper {
      display: flex
      flex-direction: row
      margin-bottom: 5.25rem

      h3 {
        // font-size: 2rem
        // line-height: 2.5rem
        margin 0
        padding 0
        width: 50%;
        margin-right: 2.5rem;
      }

      p {
        font-size: 1.125rem
        line-height: 1.625rem
        width: 50%
      }
    }

    .video-wrapper {
      display: flex
      flex-direction: row
      margin-top: 6rem
      margin-bottom: 5.25rem

      div {
        margin-right: 2.5rem
        width 33%
        h2 {
          // font-size: 2rem
          // line-height: 2.5rem
          margin 0
          padding 0
        }
      }
      
      img {
        width 66%
      }

      ::v-deep #silentbox-gallery {
        margin 0
        width auto
        img {
          width: 66%2.5rem
        }
      }

    }
  }
  
  .desktop-only {
    display block
    width 1220px
    margin 0 auto

    .card-container {
      display flex
      flex-direction: row;
      justify-content space-between
      
      .card {
        display flex
        flex-direction column
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius 8px
        position relative
        width 32%

        .img-wrapper {
          // position absolute
          height: 170px;
          top: 0;
          width: 100%;
          text-align left
          overflow: hidden
          
          img {
            margin 0
            transform: translateZ(0);
            object-fit: cover;
          }

          .name-wrapper {
            position absolute
            top 0
            left 0
            padding-left 0.8125rem
            padding-top 4rem
            height 130px
            display flex
            flex-direction column
            justify-content center
            
            p {
              margin 0
              font-size 1.9375rem
              line-height 1.9375rem
              font-weight 900
              &.first {
                color #053160
              }
              &.last {
                color #86BD27
              }
            }
          }
        }

        .content {
          padding 1.75rem 0.875rem 2.5rem

          p {
            margin 0
            font-size 1.1rem
            line-height 1.625rem
          }
        }
      }
      
    }
  }
}

::v-deep .silentbox-item
  img
    // min-height: 490px;
    border-radius 0.5rem
    width 100%
    // margin-top 1.3125rem

::v-deep #silentbox-overlay__embed {
  width: 100%;
  height: 50%;
  // min-height: 250px;
}
::v-deep #silentbox-overlay__close-button {
  right 0
  // top 21.2%
  // width: 100%;
  // background-color: black;

  .icon {
    right: 0;
    left: 0;
    margin: 0 auto;
    margin-right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (orientation: landscape) {
    ::v-deep #silentbox-overlay__embed {
      width: 100%;
      height: 85%;
      min-height: inherit;
      margin: 0;
      top: 50%;
      transform: translateY(-50%);
    }
}
@media (min-width: 1024px) {
  ::v-deep #silentbox-overlay__embed {
    width: 75%;
    height: 80%;
  }
  ::v-deep #silentbox-overlay__close-button {
    right 0
    // top 0
    // background-color: inherit;
    // width: 2.5rem;

    .icon {
      right: 0px;
      left: 0.7rem;
      margin: 50% 50% 0 0;
      top: -0.5rem;
      transform: translateY(0);
    }
  }
}

</style>
