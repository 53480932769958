import Vue from 'vue'
import App from './App.vue'
import { store } from './store'
import Axios from 'axios'
import VueSilentbox from 'vue-silentbox'
import VueSocialSharing from 'vue-social-sharing'
import Vuelidate from 'vuelidate'
let VueScrollTo = require('vue-scrollto');
import { ObserveVisibility } from 'vue-observe-visibility'
import VModal from 'vue-js-modal'
import router from './router'





Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$http = Axios

Vue.use(VueSilentbox)
Vue.use(VueSocialSharing)
Vue.use(Vuelidate)
Vue.use(VModal)
Vue.directive('observe-visibility', ObserveVisibility)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 400,
  easing: "ease",
  offset: store.isMobile() ? -60 : -72,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
