<template>
    <div :class="{'open': open}" class="modal">
       <div class="actions">
         <div class="button-wrapper">
           <p class="button close" @click="closeModal()">X</p>
         </div>
        </div>
        <h1 class="title">Impressum</h1>
        <p class="content">
          <strong>#DIE34ER - Eine Initiative des</strong><br>
          <br>
          AfW – Bundesverband Finanzdienstleistung e.V.<br>
          Kurfürstendamm 37<br>
          10719 Berlin<br>
          Tel: 030 / 63 96 43 7 – 0<br>
          Fax: 030 / 63 96 43 7 – 29<br>
          <br>
          E-Mail: office@afw-verband.de<br>
          <br>
          Amtsgericht Charlottenburg<br>
          VR-Nr. 27414<br>
          <br>
          Inhaltlich Verantwortlicher: Geschäftsführender Vorstand Norman Wirth<br>
          <br>
          Wichtige Hinweise<br>
          Bei allen Texten auf dieser Website handelt es sich lediglich um allgemeine Informationen. Für die Richtigkeit, Vollständigkeit und Aktualität kann keine Haftung übernommen werden.<br>
          <br>
          Sollte Ihnen ein Fehler auffallen, so würden wir uns freuen, wenn Sie uns davon in Kenntnis setzen. Wir bedanken uns schon im Voraus für Ihre Unterstützung. Die Inhalte unserer Website dienen der allgemeinen Information und erheben keinen Anspruch auf Vollständigkeit. Sie spiegeln lediglich die persönliche Meinung des Verfassers zum jeweilig angegebenen Erstellungszeitpunkt wieder. Sofern Sie eine Frage haben, bitten wir Sie, sich diesbezüglich an uns zu wenden.<br>
          <br>
          Für den Inhalt der angegebenen Links zu anderen Websites sind deren Betreiber verantwortlich. Eine Haftung ist ausgeschlossen.<br>
          <br>
          Copyright-Informationen<br>
          Das gesamte Dokument wurde in allen seinen Teilen vom Verfasser bzw. dem Webdesigner selbst erstellt und unterliegt dem Urheberrecht, soweit nicht anders ausdrücklich vermerkt ist.<br>
          Das Kopieren und Weitergeben des Dokuments für nichtkommerzielle Zwecke ist erlaubt, sofern keine seiner Bestandteile verändert werden.<br>
          <br>
          Die Übernahme einzelner Elemente in andere Websites ist nicht gestattet. Die kommerzielle Veröffentlichung auf Datenträgern, WWW-Servern, in Online-Diensten oder Mailboxen ist nur mit Zustimmung der Verfasser erlaubt. Das Zitieren einzelner Passagen des Dokumentes hat unter Angabe der Quelle „http://www.die34er.de“ zu erfolgen.<br>
          <br>
          Die Weitergabe der gespeicherten Daten an Dritte, auch in Auszügen, findet nicht statt.<br>
          <br>
          Layout | Konzeption | redaktionelle Betreuung &amp; Weiterentwicklung<br>
          <a href="https://birkenbeul.com" target="_blank">birkenbeul communications GmbH</a><br>
          <br>
          Bildnachweis<br>
          <a href="http://www.tompeschel.de/" target="_blank" rel="noopener noreferrer">Tom Peschel</a><br>
          <br>
          Shutterstock
        </p>
    </div>
</template>

<script>
export default {
    props: ['open'],
    methods: {
      closeModal() {
        this.$router.push({path: '/'}).catch(()=>{});
      }
    }
}
</script>

<style scoped lang="stylus">
  h1 {
    color: #053160
  }
  .modal {
    font-family: 'NunitoSans';
    z-index: 99;
    background-color: #ffffff;
    // box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    margin: 0;
    width: 100%;
    height: 100%;
    // border-radius: 6px;
    transition-property: top;
    transition-duration: 900ms;
    overflow scroll;
    padding: 3rem 3rem 2rem 2rem;
  }
  .modal:not(.open) {
    display: none;
  }
  .modal.open {
    animation: fadeup 250ms;
  }
  @keyframes fadeup {
    from { opacity: 0; top: 53%; }
    to { opacity: 1; top: 50%; }
  }
  .modal .actions {
    position: sticky
    top 0
    right 0

    .button.close {
      position: absolute;
      top: 0;
      right: 0;
      padding-inline: 1.5rem;
      color: #86bd27;
      font-size: 2rem;
      padding: 1rem;
      cursor: pointer;
      z-index: 2;
      margin-top: -3rem;
      margin-right: -3rem;
    }
  }
  a {
    color: #053160;
  }

  @media (min-width: 640px) {
    .modal {
      width: 60vw;
      height: 85vh;
    }
  }
</style>