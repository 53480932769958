import { render, staticRenderFns } from "./DerAfw.vue?vue&type=template&id=a405dfe0&scoped=true&"
import script from "./DerAfw.vue?vue&type=script&lang=js&"
export * from "./DerAfw.vue?vue&type=script&lang=js&"
import style0 from "./DerAfw.vue?vue&type=style&index=0&id=a405dfe0&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a405dfe0",
  null
  
)

export default component.exports