<template>
  <div class="home">
    <!-- <div v-if="!state.showRegisterForm">
    </div> -->
    <!-- <RegisterView /> -->
    
    <Hero />
    <DerAfw />
    <Die34er />
    <Ambassadors />
    <!-- <Termine /> -->
    <!-- <VerbandsServices /> -->
    <!-- <WillkommenImClub /> -->
    <Highlights />
    <Support />
    <More />
    <Footer theme="green" />
    <v-modal :open="imprintOpen" @close="imprintOpen = false">
    </v-modal>
    <!-- <div v-else>
      <RegisterView />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '../components/Hero.vue'
import DerAfw from '../components/DerAfw.vue'
import Die34er from '../components/Die34er.vue'
import Ambassadors from '../components/Ambassadors.vue'
// import Termine from '../components/Termine.vue'
// import VerbandsServices from '../components/VerbandsServices.vue'
// import WillkommenImClub from '../components/WillkommenImClub.vue'
import Footer from '../components/Footer.vue'
// import RegisterView from '../components/RegisterView.vue'
import Highlights from '../components/Highlights.vue'
import Support from '../components/Support.vue'
import More from '../components/More.vue'

import vModal from '@/components/ModalImprint.vue'


export default {
  name: 'Home',
  components: {
    Hero,
    DerAfw,
    Die34er,
    Ambassadors,
    // Termine,
    // VerbandsServices,
    // WillkommenImClub,
    Footer,
    // RegisterView,
    Highlights,
    Support,
    More,
    vModal
  },
  data() {
    return {
      imprintOpen: true,
    }
  },
  created() {
    this.checkModal();
  },
  watch: {
    $route(to,from) {
      this.checkModal();
    },
    // open(to,from) {
    //   if (to == false) {
    //     if (this.$route.params.modal) {
    //       this.$router.push('/')
    //     }
    //   }
    // }
  },
  mounted() {
    if (this.$store.state.modalCurrentStep === 'register-success' || this.$store.state.modalCurrentStep === 'login') {

      let _bonus;
      // check if localStorage has a key 'bonus'
      if (localStorage.getItem('bonus')) {
        _bonus = localStorage.getItem('bonus');
      }else {
        _bonus = 'highlights';
      }

      setTimeout(() => {
        let element = document.getElementById(_bonus);
        let headerOffset = this.isMobile ? 60 : 100; 
        let elementPosition = element.getBoundingClientRect().top;
        let windowOffsetY = window.pageYOffset;
        let offsetPosition = elementPosition + windowOffsetY - headerOffset;
        // document.getElementById(_bonus).scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start'
        // });
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 250)
    }
  },
  methods: {
    checkModal() {
      this.$route.name === 'Impressum' ? this.imprintOpen = true : this.imprintOpen = false;
    },
    showModal() {
      this.$router.push("impressum").catch(()=>{})
    }
  },
}
</script>