<template>
  <div id="die34er" class="section die-34er">
    <div class="hero-img">
      <h2>Willkommen <br/>im Club <br> <span>#DIE34ER</span></h2>
    </div>
    <div class="text-wrapper">
      <p class="col-white">#DIE34ER ist deine Online-Community. Hier triffst du Menschen aus ganz Deutschland, die unabhängig beraten, knüpfst in einem einzigartigen Netzwerk neue Kontakte zu anderen unabhängigen Profis und bekommst viele weitere Vorteile. Werde Teil einer starken Gemeinschaft!</p>
      <h3 class="col-green">Hier sind alle so wie du:</h3>
    </div>
    <div class="accordion-container">
      <div class="accordion-wrapper" v-for="(item, index) in items" :key="index">
        <div class="accordion-item" :class="{active: isActive.includes(index)}" @click="toggleActive(index)">
          <i class="icon" :class="item.icon"/>
          <span>{{item.title}}</span>
          <i class="icon-small" :class="isActive.includes(index) ? 'ico-up' : 'ico-down'"/>
        </div>
        <div class="accordion-content">
          <p class="small col-white" v-html="item.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Die34er',
  data() {
    return {
      items: [
        {
          icon: "ico-eagle",
          title: "Unabhängig und kundenorientiert",
          content: "Alle Mitglieder der Community #DIE34ER sind selbstständig und wirtschaftlich unabhängig von Produktanbietern. Sie handeln ausschließlich nach dem Mandat derjenigen, die sie beauftragen und beraten sie rein bedarfsorientiert. Das unterscheidet uns grundlegend von den Vermittelnden, die an einen oder mehrere Produktanbieter gebunden sind."
        },
        {
          icon: "ico-quality",
          title: "Qualifiziert",
          content: "Alle Mitglieder der Community #DIE34ER weisen ihre Fachkenntnisse bei der IHK-Registrierung nach und bilden sich kontinuierlich weiter. Somit verfügen wir über eine überprüfbare Qualifikation zur fundierten und kundenorientierten Finanz- und Versicherungsberatung."
        },
        {
          icon: "ico-diamond",
          title: "Werteorientiert",
          content: "Mit ihrer Registrierung bekennen sich alle Mitglieder der Community #DIE34ER öffentlich zu ihren Werten. Diese umfassen die unbedingte Kundenorientierung sowie die Wahrung eines einwandfreien Leumundes. So stärken wir die positive Wahrnehmung unseres Berufsstands in der Öffentlichkeit."
        },
      ],
      isActive: [0],
    }
  },
  methods: {
    toggleActive(index) {
      if (this.isActive.includes(index)) {
        this.isActive = this.isActive.filter(i => i !== index);
        return;
      }

      this.isActive.push(index);
    }
  }
}
</script>

<style scoped lang="stylus">

  .die-34er
    background-color #2E2E38
    padding-bottom 3.75rem

    img
      width 100vw

    .hero-img
      background-image url('~@/assets/img/hero4b.jpg')
      // background-size 165%
      // background-position 0% 33%
      background-size: cover;
      background-position: 42%;
      height 240px
      
      @media (min-width:801px) {
        height 500px
        background-size: cover;
        background-position: 80%;
      }

    h2
      // font-size 1.625rem
      // line-height 1.625rem
      color #053160
      padding 3.5rem 0 0 2.5rem
      text-transform uppercase
      @media (min-width:801px) {
        // font-size 4.125rem
        // line-height 4.125rem
        // padding-left 10vw
        max-width: 1220px;
        margin: 0 auto;
        padding-left: 0;
      }
      
      span 
        color #86BD27

    h3
      // font-size 1.125rem
      // line-height 1.3125rem
      color #86BD27
      padding 0 4.2rem 0 2.2rem
      padding-bottom 1.19rem
      margin-top 1.875rem
      padding-right: 8rem;

    p 
      font-size 0.875rem
      line-height 1.25rem
      padding 2.625rem 2.2rem 0
      margin-bottom 0
      color #fff
    
    .accordion-wrapper
      margin-top 1rem
      padding 0 2.3125rem 0 3.5rem

      p
        padding 0
        margin 0

      .accordion-item
        height 70px
        display flex
        align-items center
        position relative

        .icon-small
          width 24px
          height 24px
          background-size contain
          background-repeat no-repeat
          position absolute
          right 0
          transition transform .5s ease

          &.ico-down
            background-image url('~@/assets/img/icon_down_green.svg')
          &.ico-up
            background-image url('~@/assets/img/icon_down_green.svg')
            transform rotate(-180deg)


        .icon
          width 64px
          height 78px
          background-size contain
          background-repeat no-repeat
          margin-right 1.125rem

          &.ico-eagle
            background-image url('~@/assets/img/ico-eagle.png')

          &.ico-quality
            background-image url('~@/assets/img/ico-quality.png')

          &.ico-diamond
            background-image url('~@/assets/img/ico-diamond.png')

        &.active
          +.accordion-content
            max-height 500px
            transition max-height 0.5s ease-in

        span
          font-size 0.875rem
          line-height 1.25rem
          font-weight 900
          color #fff

      .accordion-content
        border-bottom 1px solid #707070
        max-height 0
        overflow hidden
        transition max-height 0.33s ease-out
        padding-top 0.5rem

        .small
          font-size 0.75rem
          line-height 1.125rem 
          font-style italic
          color #fff
          padding-bottom .75rem
  
  @media (min-width:801px) {
    .text-wrapper {
      max-width 1220px
      display flex
      flex-direction row-reverse
      margin 0 auto
      padding 6.25rem 0 2rem

      h3 {
        padding 0
        width 50%
        // font-size 2rem
        // line-height 2.5rem
        margin 0
      }
      p {
        width 50%
        font-size 1.125rem
        line-height 1.625rem
        padding 0
      }
    }
    
    .accordion-container {
      max-width 1220px
      display flex
      justify-content space-between
      margin 0 auto
      
      .accordion-wrapper {
        padding 0
        width 31%

        // &:not(:last-child) {
        //   padding-right 2rem
        // }

        // &:not(:last-child) {
        //   padding-right 2.375rem
        // }

        .accordion-item {

          .icon-small {
            display none
          }

          .icon {
            width 102px
            height 124px
          }

          span {
            font-size 1.125rem
            line-height 1.5rem
          }
        }

        .accordion-content {
          max-height 500px
          border none
          margin-top 1.5rem

          .small {
            font-size 1.1rem
            line-height 1.5rem
          }
        }
      }
    }
  }

@media (min-width: 640px) {

}

@media (min-width: 768px) {
  .die-34er {
    h2, 
    .text-wrapper,
    .accordion-container  {
      padding-inline: 3rem
    }
  }
}

@media (min-width: 1024px) {
  
}

@media (min-width: 1280px) {
  .die-34er {
    h2, 
    .text-wrapper,
    .accordion-container  {
      padding-inline: 0
    }
  }
}

</style>
