<template>
  <div id="support" class="section support">
    <div class="wrapper">
      <div class="text-wrapper">
        <h2><span>#DIE34ER</span> werden unterstützt von:</h2>
      </div>
      <!-- <div class="dates-container">
        
      </div> -->

      <div>
        <hooper 
          ref="carousel" 
          :settings="compHooperSettings"
          @slide="updateCarousel"
          v-observe-visibility="observerSettings"
          :class="staticPartnerMode ? 'no-nav' : ''"
          v-if="!staticPartnerMode"
        >
          <slide v-for="(item,index) in supporter" :key="index" :class="{ 'is-clone': false }">
            <!-- <img :src="item.img" :alt="item.name"> -->
            <transition name="person" mode="out-in">
              <a :href="item.url" target="_blank">
                <div class="my-card grid-x vertical-center p-10" :key="item.id" style="width: clamp(13.125rem, 5.0967rem + 34.2541vw, 32.5rem)">
                  <img class="img" :src="item.img" :alt="item.name" width="20%">
                </div>
              </a>
            </transition>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
        <div v-else class="partner-wrapper">
          <div class="container">
            <a :href="compPartnerObject.url" target="_blank">
              <div class="card image-wrapper">
                <img :src="compPartnerObject.img" :alt="compPartnerObject.name" width="20%" class="img">
              </div>
            </a>
          </div>
          <!-- <section tabindex="0" class="hooper">
            <div class="hooper-list">
              <ul class="hooper-track" style="transform: translate(-778.667px, 0px);">
                <li aria-hidden="true" class="hooper-slide" style="width: 389.333px;">
                </li>
                <li class="hooper-slide is-prev" aria-hidden="true" style="width: 389.333px;">
                </li>
                <li class="hooper-slide is-active" style="width: 389.333px;">
                </li>
                <li class="hooper-slide is-active is-current" style="width: 389.333px;">
                  <div  class="my-card grid-x vertical-center p-10" style="width: clamp(13.125rem, 5.0967rem + 34.2541vw, 32.5rem);">
                    <img :src="compPartnerObject.img" :alt="compPartnerObject.name" width="20%" class="img">
                  </div>
                </li>
              </ul>
            </div>
          </section> -->
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { 
  Hooper, 
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Support',
  components: {
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation
  },
  data() {
    return {
      state: this.$store.state,
      observerSettings: {
        callback: this.visibilityChanged,
        throttle: 300,
        intersection: {
          threshold: 1,
        }
      },
      staticPartnerMode: false,
      isCarouselVisible: false,
      hooperSettings: {
        itemsToShow: 1.25,
        itemsToSlide: 1,
        wheelControl: false,
        centerMode: true,
        infiniteScroll: true,
        autoPlay: false,
        playSpeed: 1500,
        hoverPause: true,
        mouseDrag: false,
        touchDrag: false,
        shortDrag: false,
        wheelControl: false,
        keyControl: false,
        breakpoints: {
          640: {
            itemsToShow: 1.5
          },
          768: {
            itemsToShow: 2
          },
          1024: {
            itemsToShow: 2.5,
          },
          1280: {
            itemsToShow: 3,
          },
        }
      },
      supporter: [
        {
          id: 1,
          isActive: false,
          nanoId: '6aa53f',
          name: 'Pfefferminzia',
          img: this.getImgUrl('logo_pfefferminzia'),
          url: 'https://www.pfefferminzia.de/'
        },
        {
          id: 2,
          isActive: false,
          nanoId: 'cefca2',
          name: 'DASINVESTMENT',
          img: this.getImgUrl('logo_das_investment'),
          url: 'https://www.dasinvestment.com/'
        },
        {
          id: 3,
          isActive: false,
          nanoId: 'f298ae',
          name: 'Procontra-Online',
          img: this.getImgUrl('logo_procontra'),
          url: 'https://www.procontra-online.de/'
        },
        {
          id: 4,
          isActive: false,
          nanoId: 'f7b73e',
          name: 'Versicherungsbote – Informationen für Versicherungsmakler',
          img: this.getImgUrl('logo_versicherungsbote'),
          url: 'https://www.versicherungsbote.de/'
        },
        {
          id: 5,
          isActive: false,
          nanoId: 'a56461',
          name: 'expertenReport – Versicherungs- und Finanznachrichten',
          img: this.getImgUrl('logo_expertenreport'),
          url: 'https://www.experten.de/'
        },
        {
          id: 6,
          isActive: false,
          nanoId: 'c7cbef',
          name: 'finanzwelt | Das Fachmagazin für Financial Intermediaries',
          img: this.getImgUrl('logo_finanzwelt'),
          url: 'https://finanzwelt.de/'
        },
        {
          id: 7,
          isActive: false,
          nanoId: 'e33ff7',
          name: 'cash.online – Finanznachrichten',
          img: this.getImgUrl('logo_cashonline'),
          url: 'https://www.cash-online.de/'
        },
        {
          id: 8,
          isActive: false,
          nanoId: 'bee2e7',
          name: 'DVB Deutsche Versicherungsbörse – Das Fachportal für die Versicherungsbranche',
          img: this.getImgUrl('logo_dvb_neu'),
          url: 'https://www.deutsche-versicherungsboerse.de/'
        },
        {
          id: 9,
          isActive: false,
          nanoId: 'e149e7',
          name: 'AssCompact – Fachmagazin für Risiko- und Kapitalmanagement',
          img: this.getImgUrl('ass-compact-logo'),
          url: 'https://www.asscompact.de/'
        }
      ],
      carouselData: 0,
    }
  },
  watch: {
    carouselData () {
      // console.log('carouselData changed', this.carouselData);
      this.$refs.carousel.slideTo(this.carouselData);
    },
    isCarouselVisible () {
      if (!this.staticPartnerMode) {
        this.hooperSettings.autoPlay = true;
        this.$refs.carousel.restart();
        let carouselInterval = setInterval(() => {
          if (this.isCarouselVisible) {
            if (this.carouselData < this.supporter.length - 1) {
              this.carouselData++;
            } else {
              this.carouselData = 0;
            }
            // this.$refs.carousel.slideNext();
          }
        }, this.hooperSettings.playSpeed);
        if (!this.isCarouselVisible) {
          clearInterval(carouselInterval);
        }
      }
    }
  },
  mounted() {
    // get index of supporter in array by nanoId from url param (sid), if param is set
    if (location.search != '') {
      let sid = /sid=([^&]+)/.exec(location.search)[1];
      let index = this.supporter.findIndex(supporter => supporter.nanoId === sid)

      if (index >= 0) {
        this.staticPartnerMode = true;
      }
      if(index < 0) index = 0;
      
      this.carouselData = index;
    };
  },
  computed: {
    compHooperSettings() {
      return {
        ...this.hooperSettings,
        autoPlay: !this.staticPartnerMode && this.isCarouselVisible,
      }
    },
    compPartnerObject() {
      return this.supporter[this.carouselData];
    }
  },
  methods: {
    getImgUrl(imageName) {
      let mobileSuffix = ''
      if ( this.isMobile ) {
        mobileSuffix = ''//'_mobile'
      }
      let images = require.context('../assets/img/', false, /\.png$/)
      return images('./' + imageName + mobileSuffix + ".png")
    },
     slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    visibilityChanged(isVisible, entry) {
      console.log('carousel is visible: ', isVisible);
      this.isCarouselVisible = isVisible;
      // this.hooperSettings.autoPlay = isVisible;
      // this.$refs.carousel.restart();
      // this.$refs.carousel.restartTimer();
    },
  },
}
</script>

<style scoped lang="stylus">
.support
  // padding-top 1rem
  font-size 1rem
  background-color: #F8F8F8
  // padding 7rem 8.5rem 11.5rem
  padding clamp(unquote('2.625rem, 0.9934rem + 6.9613vw, 6.5625rem')) clamp(unquote('2.25rem, -0.3398rem + 11.0497vw, 8.5rem')) clamp(unquote('5rem, 4.7151rem + 1.2155vw, 5.6875rem'))

  .wrapper
    padding-bottom 2.25rem

  .text-wrapper
    width: 100%
    margin 0 auto
    text-align: center

    h2
      // font-size 1.625rem
      // line-height 1.625rem
      color #053160
      text-transform uppercase
      padding-bottom: 3rem
      
      // @media (min-width:801px) {
      //   font-size 4.125rem
      //   line-height 4.125rem
      // }

      span
        color #86BD27

.my-card {
  width:350px!important
  height: 100%

  .img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 330px;
    max-width: 100%;
    position: relative;
  }
}

>>> .hooper-slide {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0.25rem 0.5rem rgba(0,0,0,0.1);
  padding: .5rem;
  // width: 27%;
  // height: 100%;
  width:380px;
  // height: 180px;
  transition: all 0.3s ease
  &:not(.is-current) {
    transform: scale(0.7)
    opacity: 0
    pointer-events: none
  }
  // height: 160px;
  height: clamp(unquote('10rem, 4.2248rem + 24.6409vw, 23.9375rem'))
  align-self: center;

}

>>> .hooper-pagination {
  bottom: -70px;

  button {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 1px solid #053160;
    background-color: transparent;
    margin 0 8px;

    &.is-active {
      background-color: #053160;
      border-color: #053160;
    }
  }
}

>>> .hooper-navigation {
  button {
    width 18px
    height 35px
    background-size contain
    background-repeat no-repeat
    // margin-right 1.125rem
  }
}

>>> .hooper-prev {
  left: 0;
  margin-right 1.125rem
  background-image url('~@/assets/img/arrow_left.svg')
  
  svg {
    display none
  }
}

>>> .hooper-next {
  right: -10px;
  margin-left 1.125rem
  background-image url('~@/assets/img/arrow_right.svg')

  svg {
    display none
  }
}

>>> .hooper {
  height: clamp(unquote('12.5rem, 7.3204rem + 22.0994vw, 25rem'));
}

.no-nav {
  >>> .hooper-navigation,
  >>> .hooper-pagination {
    opacity 0
    pointer-events: none
    display: none
  }
}

.partner-wrapper {
  .container {
    .card {
      background-color: #fff
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 15px;
      height: 30vh
      padding 1.5rem
      img {
        display: block;
        margin: 0 auto;
        width: 80%;
        height: 100%;
        object-fit: contain
        transform: translateZ(1px);
      }
    }
  }
}

@media (min-width: 640px) {
  .partner-wrapper {
    .container {
      display: flex;
      justify-content: center;

      .card {
        height: 260px
        max-width: 380px

        img {
          width: 80%;
          height: 100%;
          object-fit: contain
          transform: translateZ(1px);
        }
      }
    }
  }
  >>> .hooper-slide {
    padding: 1rem;
  }
}

@media (min-width: 768px) {
  .partner-wrapper {
    .container {
      display: flex;
      justify-content: center;

      .card {
        height: 320px
        max-width: 480px

        img {
          width: 80%;
          height: 100%;
          object-fit: contain
          transform: translateZ(1px);
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .partner-wrapper {
    .container {
      display: flex;
      justify-content: center;
      
      .card {
        height: 420px
        max-width: 580px

        img {
          width: 70%;
          height: 100%;
          object-fit: contain
          transform: translateZ(1px);
        }
      }
    }
  }

  .support {
    .text-wrapper {
      width: 50%;
      h2 {
        padding-bottom: 6rem
      }
    }
  }

  >>> .hooper-prev {
    left: 20%;
  }

  >>> .hooper-next {
    right: 20%;
  }

  >>> .hooper-pagination {
    bottom: -70px;

    button {
      width: 23px;
      height: 23px;
      border-radius: 100%;
      border: 1px solid #053160;
      background-color: transparent;
      margin 0 8px;

      &.is-active {
        background-color: #053160;
        border-color: #053160;
      }
    }
  }

  >>> .hooper-navigation {
    button {
      width 64px
      height 78px
      background-size contain
      background-repeat no-repeat
      // margin-right 1.125rem
    }
  }

  // >>> .hooper-slide {
  //   height: 383px;
  // }

}

@media (min-width: 1280px) {
  
}
</style>
