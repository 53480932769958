<template>
  <div class="footer-wrapper" :class="theme">
    <div class="container">
      <p class="afw-name">#DIE34ER</p>
      <div class="link-wrapper">
        <a href="#" v-scroll-to="'#die34er'">Vorteile</a>
        <a href="#" v-scroll-to="'#ambassadors'">Botschafter:innen</a>
        <a href="#" v-scroll-to="'#highlights'">Highlights</a>
        <a href="#" v-scroll-to="'#bundesverband'">Der Bundesverband</a>
      </div>
      <div class="social-icons">
        <a href="https://www.facebook.com/DIE34ER" target="_blank"><i class="icon ico-facebook" /></a>
        <a href="https://www.linkedin.com/company/die34er/" target="_blank"><i class="icon ico-linkedin" /></a>
        <!-- <a href="#"><i class="icon ico-instagram" /></a> -->
        <!-- <a href="#"><i class="icon ico-twitter" /></a> -->
      </div>
      <div class="copyright">
        <span>&copy; 2022</span>
        <br>
        <!-- <a href="#">Impressum</a> -->
        <router-link to="/impressum">Impressum</router-link>
        <a href="https://www.bundesverband-finanzdienstleistung.de/datenschutzerklaerung/" target="_blank">Datenschutz</a>
        <a href="https://www.bundesverband-finanzdienstleistung.de/nutzungsbedingungen/" target="_blank">Nutzungsbedingungen</a>
      </div>
      <br>
      <br>
      <p class="afw-name">#DIE34ER – eine Community des Bundesverband Finanzdienstleistung – AfW e.V.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    theme: String
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="stylus">
.footer-wrapper.footer-wrapper
  padding-block 2.25rem
  text-align center
  
  &.green
    background-color #86BD27
    color #053160
  
  &.blue
    background-color #053160
    color #fff
    .link-wrapper
      a
        color #fff
    .copyright
      span, a
        color #fff
    .social-icons
      a
        .icon
          background-color #fff

  .afw-name
    font-size 0.875rem
    font-weight 900
    line-height 1.25rem

  .link-wrapper
    a
      font-size 0.75rem
      line-height 1.125rem
      text-decoration: none;
      font-weight 400
      color #053160
      padding: 0 0.5rem;
      position relative
      display inline

      &:not(:last-child)::after
        content:"|"; 
        // border:1px solid #053160;
        // border-width: 1px 1px 0 0;
        position: absolute; 
        // right:0; 
        top:0; 
        margin-left: .4rem;
        height: 67%;
        // top: 33%;
        transform: translateY(-25%);


  .copyright
    span, a
      font-size 0.75rem
      line-height 1.125rem
      text-decoration: none;
      color #053160

    span
      margin-right 0.75rem

    a 
      padding: 0 0.5rem;
      position relative
      font-weight 400
      display inline
      
      &:not(:last-child)::after
        content:"|"; 
        // border:1px solid #053160;
        // border-width: 1px 1px 0 0;
        position: absolute; 
        // right:0; 
        top:0; 
        margin-left: .4rem;
        height: 67%;
        // top: 33%;
        transform: translateY(-25%);
      

  .social-icons
    margin 2.75rem 0 0.75rem

    a
      display inline-block

      &:not(:last-child)
        margin-right 0.75rem

      .icon
        width 24px
        height 24px
        background-color #053160
        // background-color #fff
        display block
        // border-radius 100%

        &.ico-facebook
          mask-image: url('~@/assets/img/icon_social_facebook.svg')
        &.ico-instagram
          mask-image: url('~@/assets/img/icon_social_instagram.svg')
        &.ico-twitter
          mask-image: url('~@/assets/img/icon_social_twitter.svg')
        &.ico-linkedin
          mask-image: url('~@/assets/img/icon_social_linkedin.svg')
          transform translateY(3px)

@media (min-width:801px) {
  .footer-wrapper {
    .container {
      max-width: 1220px
      margin 0 auto
      display flex
      flex-direction: column;
      justify-content: center;

      .afw-name {
        font-size: 1.125rem;
        line-height: 1.5rem;
      }

      .link-wrapper {
        margin-top: .5rem

        a {
          font-size: 1.125rem;
          line-height: 1.5rem;
          padding 0 0.7rem

          &:not(:last-child)::after {
            top:0; 
            margin-left: .7rem;
            height: 0;
          }
        }
      }

      .social-icons {
        margin-top: 2rem;
      }

      .copyright {
        margin-top: 1.6rem;

        span, a {
          font-size: 1.125rem;
          line-height: 1.5rem;
          padding 0 0.7rem

          &:last-child {
            padding-right 0;
          }

          &:not(:last-child)::after {
            top:0; 
            margin-left: .7rem;
            height: 0;
          }
        }
      }
      
    }
  }
    
}
      
</style>
